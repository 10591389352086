<template>
	<component
		:is="wrap"
		v-if="show"
		:class="['text', customClasses, { adminMode, isCurrentlyEditing }]"
		:data-key="id"
		@click="editLanguage"
	>
		<span
			v-if="typeof plural === 'number'"
			class="plural"
			role="text"
		>
			{{ pluralLang }}
		</span>
		<span
			v-else
			class="singular"
			role="text"
		>
			<i18n
				v-if="!isFallback && i18nKey !== 'blankString'"
				:path="i18nKey"
				:class="{'whitespace-pre': (
					typeof $t(i18nKey) === 'string' &&
					$t(i18nKey).indexOf('\n') > -1
				)}"
			>
				<slot />

				<!-- Helpers for new line / {} -->
				<template #[`_br`]><br></template>
				<template #[`_bracketL`]>&#123;</template>
				<template #[`_bracketR`]>&#125;</template>

				<!-- Variables which aren't provided as slots -->
				<template
					v-for="variable in variablesInLangString"
					#[variable]
				>
					<span :key="variable" role="none">{{ variableValue(variable) }}</span>
				</template>

				<!-- Links -->
				<template
					v-for="link in linksInLangString"
					#[link.value]
				>
					<a
						v-if="linksAsVariables"
						:key="link.value"
						:href="link.href"
					>
						{{ link.value }}
					</a>
				</template>

				<!-- Slots passed in we pass down to the i18n component -->
				<template
					v-for="(slotContent, slotName) in scopedSlots"
					#[slotName]
				>
					<span
						:key="slotName"
					>
						<span
							v-for="(content, index) in slotContent()"
							:key="index"
							role="none"
						>
							<Vnodes :vnodes="content" />
						</span>
					</span>

				</template>
			</i18n>
			<template v-else>
				<slot name="fallback">
					<i18n v-if="i18nKey === 'blankString' && isEditing" path="blankString" />
				</slot>
			</template>

		</span>
		<slot v-bind="{ isFallback }" name="append" />
	</component>
</template>

<script>
	import { getVariableValue } from '@/utils/variables';
	import { userCan } from '@/plugins/Permissions';
	import { cAdminPanelTypes } from '@/configs/adminPanelTypes';
	import * as linkify from 'linkifyjs';

	export default {
		components: {
			Vnodes: {
				functional: true,
				render: (h, ctx) => ctx.props.vnodes
			}
		},
		inject: {
			i18nVariables: {
				default: {}
			},
			targetId: {
				default: null
			}
		},
		props: {
			id: {
				type: String,
				required: true
			},
			vars: {
				type: Object,
				default: () => ({})
			},
			editable: {
				type: Boolean,
				default: true
			},
			alwaysEditable: {
				type: Boolean,
				default: false
			},
			// What tag to wrap the text in
			wrap: {
				type: String,
				default: 'span'
			},
			// Whether to hide component completely if the key does not exist
			conditional: {
				type: Boolean,
				default: false
			},
			// Use this fallback if `id` does not exist
			fallback: {
				type: [String, Boolean],
				default: false
			},
			plural: {
				type: Number,
				default: null
			},
			/**
			 * Enables the use of links as variables, wrap any url with `{}`
			 * and link will display wrapped with an <a/> tag
			 */
			linksAsVariables: {
				type: Boolean,
				default: false
			},
			customClasses: {
				type: [String, Array],
				default: ''
			}
		},
		computed: {
			activeStack()
			{
				return this.$store.getters['admin/panel/activeStack'];
			},
			isCurrentlyEditing()
			{
				return this.activeStack?.id === this.id;
			},
			/**
			 * Get path which is passed into I18n
			 * if admin is not editing text, display default-string on front-end for user. When admin is editing text, display block-id for easy selecting.
			 * @returns {String} I18N path of the default string (when not editing text & the string does not exist) or I18N path to the string.
			 */
			i18nKey()
			{
				if(this.$store.getters['i18n/get'](this.id) !== undefined)
				{
					if((this.$store.getters['i18n/get'](this.id) === '' && this.isEditing) ||
						this.$store.getters['i18n/get'](this.id) === false)
					{
						return 'blankString'; // Default string for when an I18N is blank
					}

					return this.id; // Block ID for I18N when editing and string is not blank/has-no-data
				}

				/** show fallback or blankString if i18n is editable and does not exist */
				if((!this.$te(this.id) || this.$t(this.id) === this.id) && this.editable && this.isEditing)
				{
					if(this.fallback)
					{
						return this.fallback;
					}

					return 'blankString';
				}

				return this.fallback || this.id; // If the translation at `this.id` doesn't exist then we fall back to `fallback`, but if `fallback` isn't set, we fall back to `id` (this sounds stupid but maintains existing logic, `id` will just plat out the `id` key in plain text)
			},
			pluralLang()
			{
				return this.$tc(this.id, this.plural, this.vars);
			},

			/** @returns {Boolean} Returns true if user is (admin or has authority), and if i18n text is editable */
			isAdmin()
			{
				return userCan('manageTextEdit', 'administration') && (this.$store.getters['admin/isEditMode'] || this.alwaysEditable) && this.editable;
			},

			/** @returns {Boolean} Returns true if admin/user is currently in editing mode */
			isEditing()
			{
				return this.editable && (this.$store.getters['i18n/admin/isEditingModeEnabled'] || this.alwaysEditable);
			},
			/** @returns {Boolean} Returns true boolean if adminMode is active */
			adminMode()
			{
				return this.isEditing ? 'adminMode' : '';
			},
			forceOutput()
			{
				return this.$store.getters['i18n/admin/forceOutput'];
			},
			/** @returns {Boolean} Determines whether the i18n string exists or not */
			doesExist()
			{
				return !!this.$te(this.i18nKey);
			},
			/** Determines whether we want to completely hide the component if the lang key doesn't exist
			 * @returns {Boolean} Depending on the prop `conditional`, returns true or false.
			*/
			show()
			{
				if(this.conditional)
				{
					return this.$te(this.id);
				}

				return true;
			},
			variables()
			{
				return { ...this.i18nVariables, ...this.vars };
			},
			isFallback()
			{
				return !(this.doesExist || this.isEditing || this.forceOutput);
			},
			/** @returns The i18nPath */
			langString()
			{
				return this.$t(this.i18nKey);
			},
			variablesInLangString()
			{
				if(!this.langString?.match) return [];

				const slotNames = Object.keys(this.$scopedSlots);
				const helperNames = ['_br', '_bracketL', '_bracketR']; // filter out e.g. 'br' to allow new lines in the string as {br}
				const matches = this.langString.match(/(?:{)([\s\S]*?)(?:})/g) || [];

				const fluff = matches
					// They will be in the form {variableName} so we remove the brackets
					.map((string) => string.replace('{', '').replace('}', ''))
					// We don't want to override actual slots passed in so
					// remove variables which exist as slots, as well as
					// helpers and links
					.filter((variable) =>
					{
						const links = this.linksInLangString.map((link) => link.value);

						return ![...slotNames, ...helperNames, ...links].includes(variable);
					});

				return fluff;
			},
			linksInLangString()
			{
				return linkify.find(this.langString) || [];
			},
			scopedSlots()
			{
				return this.$scopedSlots || {};
			}
		},
		methods: {
			/**
			 * Opens Translate Panel.
			 */
			editLanguage(e)
			{
				if(this.isEditing)
				{
					e.stopPropagation();
					e.preventDefault();
					this.$store.dispatch('admin/panel/new', {
						type: cAdminPanelTypes.I18NEdit,
						id: this.id,
						key: this.id,
						subTitlePlain: `[${this.id}]`,
						fallback: this.fallback,
						variables: this.variables,
						linksAsVariables: this.linksAsVariables
					});
				}
			},
			variableValue(name)
			{
				if(typeof this.variables[name] !== 'undefined') return this.variables[name];

				return getVariableValue(name, this.$store, { entityId: this.targetId, accountId: this.accountId });
			}
		}
	};
</script>

<style scoped lang="postcss">
	.adminMode {
		cursor: pointer;
		outline: 1px dashed #999;

		&.isCurrentlyEditing {
			outline: 2px dashed var(--q-color-info);
		}
	}

	.adminMode:hover,
	.adminMode:focus {
		opacity: 0.9;
		transition: 0.3s;
		background: rgba(0, 0, 0, 0.1);
	}

	.adminMode.fallbackActive {
		background: rgba(255, 0, 0, 0.2);
	}

	.adminMode svg {
		color: #666;
		font-size: 15px;
		margin-right: 6px;
	}

	.adminMode:hover svg,
	.adminMode:focus svg {
		display: inline-block;
	}

	.text {
		/* I know how this looks but this fixes (somehow?) a bug when using I18N inside `QBtn` which doesn't have `outline` property isn't editable in admin mode */
		/* This makes sure I18N is above other components for clicking on however this forces i18n to have to be inside another element for it be clickable */
		z-index: 0;
		pointer-events: auto; /* To allow the text to be clicked on in edit mode */
	}

	.breakWord {
		hyphens: inherit;
		word-break: break-word;
	}

	.whitespace-pre {
		white-space: pre-line;
	}
</style>
