<template>
	<span :class="{ 'display-inline-block': inline }">
		<div>
			<slot
				v-if="!hideActivatorWhenOpen || (hideActivatorWhenOpen && !modelValue)"
				name="activator"
				v-bind="slotProps"
			/>
		</div>
		<q-dialog
			v-model="modelValue"
			:noEscDismiss="hasInnerModal"
			v-bind="qProps"
			:maximized="lightroom || maximized"
			style="z-index: 9999;"
			:contentStyle="contentStyle"
			@hide="modalClosed"
			@shake="onShake"
			@show="show"
		>
			<q-card
				:dark="dark || lightroom"
				:style="modalStyle"
				:class="[
					cardClasses,
					blockData ? `item-id-${blockData.id}` : undefined
				]"
			>
				<q-bar v-if="editBlockMode">
					<BlockToolbar
						:data="blockData"
						showOnlyControls
					/>
				</q-bar>
				<q-toolbar
					v-if="!hideToolbar"
					:class="{
						'defaultDarkModalTool': dark,
						'uniform-modal-toolbar': uniformDesign,
						'dark-mode': darkMode,
						'bordered': !uniformDesignBorderless,
						'more-padding': morePadding,
						'lightroom-toolbar': lightroom
					}"
				>
					<span v-if="icon" class="title-icon">
						<q-icon
							:name="icon"
							color="grey-9"
							size="24px"
						/>
					</span>
					<span v-if="title" class="title-content text-h6">{{ title }}</span>
					<slot
						v-else
						name="title"
						v-bind="slotProps"
					/>
					<q-space />
					<slot name="toolbar-controls" v-bind="slotProps" />
					<SimpleButton
						v-if="!hideCloseButton || (editMode && userCanBuild)"
						v-close-popup="!preventClosing"
						class="close"
						round
						flat
						:size="uniformDesign ? '10px' : '14px'"
						:icon="uniformDesign ? 'fal fa-times' : 'far fa-times'"
						:aria-label="$t('modal.close')"
						:color="(dark || lightroom || darkMode) ? 'grey-1' : uniformDesign ? 'grey-9' : 'primary'"
						@click="closeModal"
					/>
				</q-toolbar>

				<q-card-section
					:class="mainContentClasses"
				>
					<slot v-bind="slotProps" />
				</q-card-section>
				<!--
					using <template #controls="props"> causes !!$slots.controls === false
					according to the docs, "All $slots are now also exposed on $scopedSlots as functions"
					https://vuejs.org/v2/api/#vm-scopedSlots

					NOTE: don't use v-if on the template, as this isn't reactive - use on template content
				-->
				<!-- <q-card-actions
					v-show="$slots.controls"
					align="right"
				> -->
				<q-card-actions
					v-show="shouldShowControls"
					:class="{
						'uniform-modal-actions': uniformDesign,
						'dark-mode': darkMode,
						'bordered': !uniformDesignBorderless
					}"
				>
					<div class="items-center rjbrv full-width">
						<div class="ric">
							<slot name="controls" v-bind="slotProps" />
						</div>
						<slot name="controlsLeft" v-bind="slotProps" />
					</div>
				</q-card-actions>

				<q-inner-loading :showing="isLoading">
					<q-spinner />
				</q-inner-loading>
			</q-card>
		</q-dialog>
	</span>
</template>

<script>
	import { userCan } from '@/plugins/Permissions';

	export default {
		components: {
			BlockToolbar: () => import('@/components/blocks/BlockToolbar')
		},
		props: {
			icon: {
				type: String,
				default: null
			},
			title: {
				type: String,
				default: null
			},
			hideCloseButton: {
				type: Boolean,
				default: false
			},
			dark: {
				type: Boolean,
				default: false
			},
			height: {
				type: String,
				default: null
			},
			width: {
				type: String,
				default: null
			},
			customClasses: {
				type: [Array, Object, String],
				default: undefined
			},
			/**
			 * For the `<q-card-section>` containing the default slot.
			 * When using an object, this can serve as a substitute for:
			 * * noPadding
			 * * morePadding
			 * * noScroll
			 * * uniformDesign
			 */
			customContentClasses: {
				type: [Array, Object, String],
				default: undefined
			},
			/**
			 * By default, the modal's `max-width` is:
			 * * The `width` prop value.
			 * * The width determined by the `size` prop (or `fullWidth` and `maximized` overrides).
			 *
			 * This can be used with `width` values,
			 * which don't make for desirable `max-width` values,
			 * e.g. 'auto', 'unset', etc.
			 */
			maxWidth: {
				type: String,
				default: null
			},
			/**
			 * Equivalent of `size="fullWidth"`.
			 */
			fullWidth: {
				type: Boolean,
				default: false
			},
			fullHeight: {
				type: Boolean,
				default: false
			},
			/**
			 * Determines modal width.
			 */
			size: {
				type: String,
				default: 'large',
				validator: (prop) => [
					// 350px
					'x-small',
					// 500px
					'small',
					// 800px
					'medium',
					// 900px
					'large',
					// 100%
					'fullWidth'
				].includes(prop)
			},
			/**
			 * Overrides the `size` prop.
			 * If set, modal will fill the entire screen.
			 */
			maximized: {
				type: Boolean,
				default: false
			},
			/**
			 * Optional function called when q-dialog emits show on modal opening
			 */
			show: {
				type: Function,
				default: () =>
				{},
				required: false
			},
			seamless: {
				type: Boolean,
				default: false
			},
			persistent: {
				type: Boolean,
				default: false
			},
			position: {
				type: String,
				default: 'standard',
				validator: (prop) => [
					'standard',
					'top',
					'right',
					'bottom',
					'left'
				].includes(prop)
			},
			value: {
				type: Boolean,
				default: false
			},
			blockData: {
				type: Object,
				default: null
			},
			zIndexOverride: {
				type: Number,
				default: undefined
			},
			hideActivatorWhenOpen: {
				type: Boolean,
				default: false
			},
			removePadding: {
				type: Boolean,
				default: false
			},
			morePadding: {
				type: Boolean,
				default: false
			},
			/**
			 * Display the activator inline-block
			 */
			inline: {
				type: Boolean,
				default: false
			},
			noScroll: {
				type: Boolean,
				default: false
			},
			/**
			 * Modal is maximised and set to dark mode.
			 */
			lightroom: {
				type: Boolean,
				default: false
			},
			/**
			 * Stops displaying the title or the close button so they can be displayed from the child component for better control.
			 */
			hideToolbar: {
				type: Boolean,
				default: false
			},
			/**
			 * Additional closing function that can be provided
			 */
			onClose: {
				type: Function,
				default: () =>
				{}
			},
			/**
			 * When true, makes close button inactive and emits an event when overlay is clicked or Esc is pressed
			 */
			preventClosing: {
				type: Boolean,
				default: false
			},
			customModalStyle: {
				type: Object,
				default: null
			},
			uniformDesign: {
				type: Boolean,
				default: false
			},
			uniformDesignBorderless: {
				type: Boolean,
				default: false
			},
			isLoading: {
				type: Boolean,
				default: false
			}
		},
		data()
		{
			return {
				modelValue: this.value,
				hasEmittedClosed: false,
				hasInnerModal: false
			};
		},
		computed: {
			darkMode()
			{
				return this.$q.dark.isActive;
			},
			closeButtonColor()
			{
				if(this.dark) return 'grey-1';

				if(this.lightroom) return 'grey-5';

				return 'black';
			},
			qProps()
			{
				return { ...this.$props, fullWidth: this.size === 'fullWidth' };
			},
			userCanBuild()
			{
				return userCan('manageEditMode', 'administration');
			},
			editMode()
			{
				return this.$store.getters['admin/isEditMode'];
			},
			editBlockMode()
			{
				if(this.previewMode) return false;

				return this.editMode && this.blockData && this.userCanBuild;
			},
			slotProps()
			{
				return {
					open: this.open,
					close: this.close,
					toggle: this.toggle
				};
			},
			modalStyle()
			{
				if(this.customModalStyle) return this.customModalStyle;

				if(this.lightroom)
				{
					return {
						background: 'hsl(0, 0%, 5%, 0.9)',
						'backdrop-filter': 'blur(20px)',
						'-webkit-backdrop-filter': 'blur(20px)'
					};
				}

				let width = null,
					style = {};

				if(!this.fullWidth && !this.maximized && !this.$q.platform.is.mobile)
				{
					if(this.width)
					{
						width = this.width;
					}
					else
					{
						switch(this.size)
						{
							case 'xs':
								width = '350px';
								break;
							case 'small':
								width = '500px';
								break;
							case 'medium':
								width = '800px';
								break;
							case 'fullWidth':
								width = '100%';
								break;
							default:
								width = '900px';
								break;
						}
					}
				}
				else
				{
					width = '100%';
				}

				if(this.uniformDesign)
				{
					style = {
						...style,
						borderRadius: '10px',
						overflow: 'hidden'
					};
				}

				return {
					...style,
					width,
					maxWidth: this.getMaxWidthForWidth(width),
					height: this.height,
					// For some reason, using .column causes issues with some content,
					// e.g. the colour picker content has overflow/scroll, but this seems fine.
					display: 'flex',
					'flex-direction': 'column'
				};
			},
			contentStyle()
			{
				return {
					zIndex: this.zIndexOverride
				};
			},
			mainContentClasses()
			{
				return this.addCustomClasses(
					{
						removePadding: this.removePadding,
						'more-padding': this.morePadding,
						scroll: !this.noScroll,
						'uniform-modal-main': this.uniformDesign,
						'flex-grow': true
					},
					this.customContentClasses
				);
			},
			cardClasses()
			{
				return this.addCustomClasses(
					{
						defaultDarkModal: this.dark
					},
					this.customClasses,
					'modal-card'
				);
			},
			shouldShowControls()
			{
				return !!this.$scopedSlots.controls?.(this.slotProps);
			}
		},
		watch: {
			modelValue(value)
			{
				this.$emit('input', value);
			},
			value()
			{
				this.modelValue = this.value;
			}
		},
		mounted()
		{
			/**
			 * If a child opens a dialog 'Esc' is disabled.
			 * if the child closes its dialog, 'Esc' is enabled again.
			 * setTimeout ensures Modal doesn't close if user is trying to close child dialog
			 */
			this.$root.$on('hasInnerModal', (arg) =>
			{
				setTimeout(() =>
				{
					this.hasInnerModal = arg;
				}, 100);
			});
		},
		methods: {
			open()
			{
				this.modelValue = true;
				this.hasEmittedClosed = false; // always reset to false when the modal opens
			},
			close()
			{
				this.modalClosed();
				this.modelValue = false;
			},
			toggle()
			{
				this.modelValue = !this.modelValue;
			},
			/**
			 * Modal will shake if set to persistent and emit
			 * an event so the child component can display a dialog.
			 */
			onShake()
			{
				if(this.preventClosing)
				{
					this.$root.$emit('closeButtonDisabled');
				}
			},
			closeModal()
			{
				this.onShake();
				this.modalClosed();
			},
			modalClosed()
			{
				this.onClose();
				if(!this.hasEmittedClosed && !this.preventClosing)
				{
					this.hasEmittedClosed = true;
					this.$emit('closed');
				}
			},
			/**
			 * The `max-width` should be set,
			 * or it'll default to Quasar's values.
			 *
			 * @param {String} width
			 * @returns {String} The max width css value to apply
			 */
			getMaxWidthForWidth(width)
			{
				switch(width)
				{
					case 'auto': // Not a valid `max-width`
					case 'unset': // Is valid, but should be set, or defaulted
						return this.maxWidth || '100%';
					default:
						return width;
				}
			},
			/**
			 * Handles combining the different custom class value types with a base class object.
			 * TODO: This is a generic utility function.
			 *
			 * @param {Object<string, string>} baseClasses
			 * @param {(Array<string> | Object<string, string> | string)} customClasses
			 * @returns {(Array<Object<string, string> | string> | Object<string, string>)}
			 */
			addCustomClasses(baseClasses, customClasses)
			{
				if(!customClasses)
				{
					return baseClasses;
				}

				if(Array.isArray(customClasses))
				{
					return [
						baseClasses,
						...customClasses
					];
				}

				switch(typeof customClasses)
				{
					case 'object':
						return Object.assign({}, baseClasses, customClasses);
					case 'string':
						return [
							baseClasses,
							customClasses
						];
					default:
						return baseClasses;
				}
			}
		}
	};
</script>

<style lang="postcss">
	.q-dialog__backdrop {
		background: hsl(0, 0%, 0%, 0.8) !important;
	}
</style>

<style scoped lang="postcss">
	.title-icon {
		margin-right: 12px;
	}

	.title-content {
		word-break: break-all;
	}

	/* this messes with dark modals in admin dash
	leaving it here until the merge issues are resolved, for reference */
	/* .q-dialog {
		.q-card {
			background: #fff;
		}
	} */

	.q-dialog {
		.q-card {
			border-radius: var(--roundCorners-all-md);
			transition: width 50ms, max-width 50ms;
		}

		.q-card.q-dark {
			background: var(--admin-panel-background);
		}
	}

	.modal-card {
		position: relative;
		display: flex;
		flex-direction: column;

		.loading {
			position: absolute;
			top: 0;
			height: 100%;
			background: hsl(0, 0%, 100%, 0.7);
			width: 100%;

			&.dark {
				background: hsl(0, 0%, 0%, 0.7);
			}
		}
	}

	.uniform-modal-toolbar {
		padding: 10px var(--q-dialog-inline-padding);

		&.bordered {
			border-bottom: 1px hsl(0, 0%, 0%, 12%) solid;
			box-shadow: 0 4px 1px 0 hsl(0, 0%, 0%, 3%);

			&.dark-mode {
				border-color: hsl(0, 0%, 100%, 12%);
				box-shadow: 0 1px 0 1px hsl(0, 0%, 0%, 60%);
			}
		}

		&.more-padding {
			padding: 25px 30px 15px;
		}

		> .text-h6 {
			font-weight: 700;
			font-size: 1.3rem;
		}
	}

	.more-padding {
		padding: 20px 30px;
	}

	.uniform-modal-main {
		overflow-y: auto;
	}

	.uniform-modal-actions {
		padding: 10px var(--q-dialog-inline-padding);

		&.bordered {
			box-shadow: 0 -4px 1px hsl(0, 0%, 0%, 3%);
			border-top: 1px hsl(0, 0%, 0%, 12%) solid;

			&.dark-mode {
				border-color: hsl(0, 0%, 100%, 12%);
				box-shadow: 0 -1px 0 1px hsl(0, 0%, 0%, 60%);
			}
		}
	}

	.q-menu {
		box-shadow: var(--shadow-element-dreamy-strong);
		min-width: 340px;
	}

	.removePadding {
		padding: 0;
	}

	.display-inline-block {
		display: inline-block;
	}

	.lightroom-toolbar {
		padding: 15px 25px 0 25px;
		z-index: 6666;
		position: fixed;
		top: 0;
		width: 100vw;
		align-items: flex-start;
	}
</style>
